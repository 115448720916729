<template>
  <div id="userView">
    <Map/>
  </div>
</template>

<script>
import Map from '../components/Map/Map.vue'

export default {
  name: 'UserView',
  components: {
    Map,
  }
}
</script>
<style scoped>
  #userView {
    height: 100%;
  }
</style>
