<template>
<div id="map">
    <div class="info-wrapper">
        <search-bar @photonSelect="photonSearch" @localSelect="pinClick" @input="updateSearchCentre" :lat="state.mapProperties.center.lat" :lng="state.mapProperties.center.lng" />
        <div v-if="state.selected" style="width: 100%; height: 100%;">
            <carousel v-on:selection-change="updateWay" :details="state.details" />
        </div>
        <div v-else style="width: 100%; height: 100%;">
            <user-prompt v-bind:view="true" />
        </div>
    </div>
    <div class="map-wrapper">
        <l-map ref="myMap" @ready="initialize()" style="height: 100%" :zoom="state.mapProperties.zoom" :center="state.mapProperties.center">
            <l-tile-layer :url="state.mapProperties.url" :attribution="state.mapProperties.attribution"></l-tile-layer>
            <l-marker :key="index" v-for="(m, index) in state.markers" :lat-lng="[m.pointX, m.pointY]" @click="pinClick(m.pId)"></l-marker>
            <l-polygon :lat-lngs="state.polygons"></l-polygon>
            <l-polyline :key="'Polyline' + index" v-for="(m, index) in state.polylines" :lat-lngs="m.points" :color="state.colors.polyline"></l-polyline>
        </l-map>
    </div>
</div>
</template>

<script>
import {
    reactive
} from '@vue/composition-api'
// import L from 'leaflet';
import {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LPolyline
} from 'vue2-leaflet';
import carousel from '../Carousel/Carousel';
import userPrompt from "./UserPrompt";
import searchBar from "./SearchBar.vue";
import mapQuery from '../../api/mapQuery';
import mapfunctions from "./mapfunctions.js";

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPolygon,
        LPolyline,
        carousel,
        userPrompt,
        searchBar,

    },
    setup() {
        const state = reactive({
            mapProperties: {
                url: mapfunctions.url,
                //TODO look to add overpass attribution
                attribution: mapfunctions.attribution,
                center: mapfunctions.center,
                zoom: mapfunctions.zoom,
            },
            map: null,
            selected: false,
            markers: [],
            polygons: [],
            polylines: [],
            details: [],
            colors: {
                polygon: 'red',
                polyline: 'green'
            },
            mapGetter: {
                points: [],
                rawArr: [],
            },
            carousel: {
                selectedIndex: 0
            }
        })

        // Runs when the map is ready to be loaded in
        function initialize() {
            state.map = this.$refs.myMap.mapObject
            loadPin()
        }

        // ########## LOADING AND CLICKING PINS ##########

        // Loads all the pins from the database onto the map
        function loadPin() {
            mapQuery.loadAllPins().then(json => {
                state.markers = json.data.pinData
            }).catch(ex => {
                console.log("Couldn't load all the pins", ex)
            })
        }

        // Loads the details window with the saved details of the pin
        function pinClick(pId) {
            mapQuery.getPinInfoById(pId).then(json => {
                state.polygons = json.data.placeData[0].points
                state.details = json.data.placeData
                state.selected = true;
            }).catch(ex => {
                console.log("couldn't obtain way", ex)
                return null;
            })
        }

        // ########## SEARCH AND DATA DISPLAY ############

        // Changes the outline when the carousal switches names
        function updateWay(newIndex) {
            state.polygons = state.details[newIndex].points
        }

        // Updates the centre so that the search bar can seach by location
        function updateSearchCentre() {
            state.mapProperties.center.lat = state.map.getCenter().lat
            state.mapProperties.center.lng = state.map.getCenter().lng
            console.log("centre updated", state.mapProperties.center)
        }

        // Repositions the map after a search item has been selected from the search bar
        function photonSearch(result) {
            // If the search result has an extent (bounding box to zoom to) show the extent
            if (result.extent.length == 4) {
                let coord1 = result.extent.slice(0, 2)
                let coord2 = result.extent.slice(2)
                // Leaflter coords are the other way around from osm so they need to be swapped
                let bounds = [osmCoordToLeaflet(coord1), osmCoordToLeaflet(coord2)]
                setMapBounds(bounds)
            }
            // Else pan and zoom to it appropriately 
            else {
                // todo decide if figuring out way relation etc is worth it. Maybe just zoom to 18
                // let type = ''
                let zoomlevel = 14
                let osmType = result.osmType
                osmType = osmType.toLowerCase()
                if (osmType == 'w') {
                    // type = 'way'
                    zoomlevel = 14
                } else if (osmType == 'r') {
                    // type = 'relation'
                    zoomlevel = 7
                } else if (osmType == 'n') {
                    // type = 'node'
                    zoomlevel = 18
                }
                panAndZoom(osmCoordToLeaflet(result.latlng), zoomlevel)
            }
        }

        function panAndZoom(coord, zoomlevel) {
            state.map.flyTo(coord, zoomlevel)
        }

        function setMapBounds(bounds) {
            state.map.fitBounds(bounds)
        }

        function osmCoordToLeaflet(coord) {
            return [coord[1], coord[0]]
        }

        return {
            state,
            initialize,
            loadPin,
            pinClick,
            updateWay,
            updateSearchCentre,
            photonSearch
        }
    },
}
</script>

<style scoped>
#map {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.map-wrapper {
    width: 100%;
}

.map-container {
    margin: 0;
}

.info-wrapper {
    width: 350px;
    flex-shrink: 0;
    height: 100%;

}
</style>
