<template>
<div id="SearchBar">
    <input
      type="text"
      v-model="state.searchText"
      @input="onChange"
    />
    <ul
      v-show="state.isOpen"
      class="autocomplete-results"
    >
      <li
        v-if="state.isLoading.local"
        class="loading"
      >
      Loading results...
      </li>
      <li
        v-else-if="state.search.localEmpty"
        class="no-result"
      >
      No results
      </li>
      <li
      v-else
      v-for="(result, i) in state.search.localItems"
      :key="i"
      @click="setResultLocal(result)"
      class="autocomplete-result"
      >
        {{result.address}}
      </li>
    </ul>
    <ul
      v-show="state.isOpen"
      class="autocomplete-results"
    >
      <li
        v-if="state.isLoading.photon"
        class="loading"
      >
      Loading results...
      </li>
      <li
        v-else-if="state.search.photonEmpty"
        class="no-result"
      >
      Photon couldn't find this for you
      </li>
      <li
      v-else
      v-for="(result, i) in state.search.photonItems"
      :key="i"
      @click="setResultPhoton(result)"
      class="autocomplete-result"
      >
        {{result.address}}
      </li>
    </ul>
</div>
</template>

<script>
import {reactive} from '@vue/composition-api'
import mapQuery from '../../api/mapQuery'
export default {
    name: 'SearchBar',
    components: {
    },
    props: {
        lat: {
          type: Number,
          required: true,
        },
        lng: {
          type: Number,
          required: true
        }
    },
    // Update vars if the paramaters passed in change
    watch: {
      lat: function(val) {
        this.state.centre.lat = val
      },
      lng: function(val) {
        this.state.centre.lng = val
      }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside)
    },
    setup(props) {
        const state = reactive({
            searchText: '',
            selectedText: String,
            results: [],
            isOpen: false,
            search: {
              localItems: [],
              photonItems: [],
              localEmpty: true,
              photonEmpty: true
            },
            isLoading: {
              local: false,
              photon: false,
            },
            centre: {
              lat: props.lat,
              lng: props.lng
            }
        })

        function onChange() {
          if(state.searchText != "") {
          // Tell the parent the search text has been updated ie ready to search
          // Parent responds by sending the current centre of the map to aid search
          this.$emit('input', state.searchText)
          searchLocal()
          searchPhoton()
          state.isOpen = true
          }
          else {
            // TODO check this is the right place to do this
            state.isOpen = false
          }
        }

        function setResultLocal(result) {
          setResult(result.address)
          // Alerts the parent that a local search was selected
          this.$emit('localSelect', result.pId)
          // give pin Id to zoom to that part of map
        }

        function setResultPhoton(result) {
          setResult(result.address)
          // Alerts the parent that a photon search was selected
          this.$emit('photonSelect', result)
        }
        // Sets the text of the search bar and closes the results section
        function setResult(result) {
            state.searchText = result;
            state.isOpen = false;
        }

        function handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
            state.isOpen = false;
          }
        }
        // Searches photon using its api
        function searchPhoton() {
          state.isLoading.photon = true
          mapQuery.getPhotonResults(state.searchText, state.centre.lat, state.centre.lng).then(names => {
            // Note each element in names consists of an id, type, address and lat lng coords
            if(names.length != 0) {
              state.search.photonEmpty = false;
              state.search.photonItems = names
            }
            else {
              state.search.photonEmpty = true;
            }
            state.isLoading.photon = false
          }).catch(ex => {
            console.log("couldnt get names from photon", ex)
          })
        }
        // Searches the local database0
        function searchLocal() {
          state.isLoading.local = true
          mapQuery.getPlaceName(state.searchText).then(names => {
            // Note each element in names consists of an address and an id
            if(names.length != 0) {
              state.search.localItems = names
              state.search.localEmpty = false;
            }
            else {
              state.search.localEmpty = true;
            }
            state.isLoading.local = false
          }).catch(ex => {
            console.log("couldn't get place names", ex)
          })
        }
        return {
            state,
            onChange,
            setResultLocal,
            setResultPhoton,
            handleClickOutside,
        }
    },
}
</script>

<style scoped> 
#SearchBar {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    min-height: 1em;
    max-height: 6em;    
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>
